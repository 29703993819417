import { Button, Flex, Typography } from "@nestoca/ui";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight } from "react-icons/bs";

import { HighlightsCard } from "@components/highlights-card";

import styles from "./rate-card.module.scss";

export const RateCard = ({ ratesLink }: { ratesLink: string }) => {
  const { t } = useTranslation("dashboard");

  return (
    <HighlightsCard className={styles.card}>
      <Flex direction="column">
        <Typography size={3} weight={7}>
          {t("rateCard.title")}
        </Typography>
        <Typography size={0}> {t("rateCard.subTitle")}</Typography>
      </Flex>
      <Link href={ratesLink} passHref target="blank">
        <Button
          as="a"
          size="large"
          variant="secondary"
          rightIcon={<BsBoxArrowUpRight size={20} />}
        >
          {t("rateCard.link")}
        </Button>
      </Link>
    </HighlightsCard>
  );
};
